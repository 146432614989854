<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<h3>{{  $language.nft.choice_wallet }}</h3>
				</div>
				<div class="hlayer">
					<div class="create_wallet">
						<ul>
							<li
								v-for="(wallet, index) in items_wallet"
								:key="'wallet_' + index"
							>
								<button
									@click="$emit('click', wallet)"
								>{{ wallet.coin_token_name }} <span style="float: right">{{ wallet.holding_coin_quantity}} {{ wallet.label}}</span></button>
							</li>
						</ul>
					</div>
				</div>
			</article>
			<button
				@click="$emit('cancel')"
				class="btn_close">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
	export default {
		name: 'mafia0401'
		, props: ['items_wallet']
	}
</script>